import React from 'react';
import Icon from '../../Icon';
import CallCalendar from './CallCalendar/CallCalendar';
import { getAgendas } from '../../utils/api/agenda';
import { useQuery } from 'react-query';
import { getItemsByWeek } from '../../utils/dataWekly';
import SelectDateType from './SelectDateType';

function AgendaSelect() {
  return (
    <div>
      <SelectDateType />
      <CallCalendar />
    </div>
  );
}

export default AgendaSelect;
