import React, { Fragment } from 'react';
import './ReportingSteps.css';
import { useParams, useSearchParams } from 'react-router-dom';

function ReportingSteps() {
  const [params, setParams] = useSearchParams();
  const step = Number(params.get('step') || 1);
  const steps = ['Informations', 'importer le fichier', 'Aperçu'];

  return (
    <div className='ReportingSteps'>
      <ul>
        {steps?.map((item, i) => (
          <Fragment key={i}>
            {i > 0 && <div className='line'></div>}
            <li
              className={`${i + 1 === step ? 'active' : ''}`}
              onClick={() => {
                params.set('step', i + 1);
                setParams(params);
              }}
            >
              <span>{i + 1}</span> <p>{item}</p>
            </li>
          </Fragment>
        ))}
      </ul>
    </div>
  );
}

export default ReportingSteps;
