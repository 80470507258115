import React from 'react';
import './AddReporting.css';
import ReportingSteps from './ReportingSteps/ReportingSteps';
import InformationForm from './InformationForm/InformationForm';
import { useSearchParams } from 'react-router-dom';
import ReportingFile from './ReportingFile/ReportingFile';
import ReportingShow from './ReportingShow/ReportingShow';

function AddReporting() {
  const [params, setParams] = useSearchParams();
  const step = Number(params.get('step') || 1);

  return (
    <div className='AddReporting'>
      <ReportingSteps />
      {step === 1 && <InformationForm />}
      {step === 2 && <ReportingFile />}
      {step === 3 && <ReportingShow />}
    </div>
  );
}

export default AddReporting;
