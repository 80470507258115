import { Outlet } from 'react-router-dom';
import AppLayout from '../AppLayout';
import Reporting from '../../screens/Reporting/Reporting';
import AddReporting from '../../screens/Reporting/AddReporting/AddReporting';

const reportingRouter = {
  path: '/reporting',
  element: (
    <AppLayout>
      <Outlet />
    </AppLayout>
  ),
  children: [
    {
      path: '',
      element: <Reporting />,
    },
    {
      path: 'ajouter',
      element: <AddReporting />,
    },
    {
      path: ':id',
      element: <AddReporting />,
    },
  ],
};

export { reportingRouter };
