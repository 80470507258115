import React from 'react';
import './Chat.css';
import ChatTab from './ChatTab/ChatTab';
import ChatCard from './ChatCard/ChatCard';
import { getUsers } from '../../utils/api/users';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

function Chat() {
  const { type } = useParams();
  const { data: users, error, isLoading } = useQuery('users', getUsers);
  const { data: profile } = useQuery('profile');
  return (
    <div className='Chat'>
      <ChatTab />
      <div className='cards'>
        {users
          ?.filter((user) => user?._id !== profile?._id)
          ?.filter(
            (u) =>
              (type === 'admins' && u?.role === 'Admin') ||
              (type !== 'admins' && u?.role !== 'Admin') ||
              type === 'tout'
          )
          ?.map((user, i) => (
            <ChatCard user={user} key={i} />
          ))}

        {Array(5)
          .fill('')
          ?.map((_, i) => (
            <div className='ChatCard ' style={{ opacity: 0 }} key={i}></div>
          ))}
      </div>
    </div>
  );
}

export default Chat;
