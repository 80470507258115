import React from 'react';

function Select({
  register = () => {},
  errors = {},
  lable = '',
  name = '',
  attr = {},
  className = '',
  type = 'text',
  children,
}) {
  return (
    <div className={`input-container ${className}`}>
      <label className='bold' htmlFor=''>
        {lable}
      </label>
      <select
        className={`${errors && errors[name] ? 'error' : ''}`}
        {...register(name, { valueAsNumber: type === 'number' })}
        {...attr}
      >
        {children}
      </select>
      <span className='error'>
        {`${errors && errors[name]?.message ? errors[name]?.message : ''}`}
      </span>
    </div>
  );
}

export default Select;
