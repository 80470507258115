import React, { useRef } from 'react';
import './Upload.css';
import Icon from '../Icon/Icon';
import { upload } from '../../utils/api/upload';

function Upload({
  onUpload = (url) => {},
  onStartUpload = () => {},
  onError = () => {},
  src,
  isImage = true,
  file_id = '',
}) {
  const handleFileChange = (e) => {
    const id = file_id || Math.floor(Math.random() * 1000);
    const file = e.target.files[0];

    const { name, size, type } = file;
    onStartUpload(id, name, size, type);
    upload(file, id)
      .then((url) => {
        onUpload(url, id, name, size, type);
        e.target.value = null;
      })
      .catch((err) => {
        onError(id);
        console.log(err);
        e.target.value = null;
      });
  };

  if (isImage)
    return (
      <div className='upload upload-button'>
        <input type='file' accept='image/*' onChange={handleFileChange} />
        <img src={src || '/images/upload.svg'} />
        {src && (
          <button className='edit'>
            <Icon name='edit' />
          </button>
        )}
      </div>
    );

  return (
    <>
      <button type='button' className='upload upload-button'>
        <input
          type='file'
          onChange={handleFileChange}
          style={{ width: '100%', left: 0 }}
        />
        <div>
          Joindre la CIN / passport
          <Icon name='join' />
        </div>
        <span>Pdf, word, JPG ( 2MB )</span>
      </button>
    </>
  );
}

export default Upload;
