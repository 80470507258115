import React, { useState } from 'react';
import './FicheIntervention.css';
import Icon from '../../Icon/Icon';
import { Link } from 'react-router-dom';
import moment from 'moment';

function FicheIntervention({ value }) {
  const Value =
    100 *
    (moment().diff(moment(value?.createdAt)) /
      moment(value?.date).diff(moment(value?.createdAt)));
  const steps = [
    'Fiche d’intervention envoyé',
    'Fiche d’intervention Accepté',
    'Visite initiale',
    'Début d’intervention',
    'Confirmation et signatures',
  ];

  return (
    <div
      className={`FicheIntervention ${
        moment().diff(moment(value?.date)) >= 0 ? 'disabled' : ''
      } `}
    >
      <div className='flex-left'>
        <div className='timer'>
          <Icon name='caret-right' />
        </div>
        <div>
          <h5>à partir de</h5>
          <p>
            <Icon name='time-forward' />
            {moment(value?.date)?.format('DD/MM/YYYY')}
          </p>
        </div>
      </div>
      <div className='flex-center'>
        <h4>
          {value?.title} - {value?.position}{' '}
        </h4>
        {Value >= 0 && (
          <>
            <div className='flex' style={{ color: '#768298', fontSize: 14 }}>
              <Icon name='copy' /> Tél: {value?.client?.telephone}: Email:{' '}
              {value?.client?.email}
            </div>
          </>
        )}
      </div>
      {Value >= 0 && (
        <div className='flex-center'>
          <h3> {moment(value?.date)?.format('DD/MM/YYYY')}</h3>

          <div className='slider'>
            <div style={{ width: `${Value}%` }}></div>
          </div>
        </div>
      )}

      <div className='flex-center'>
        <Link to={`/agenda/randez-vous/${value?._id}`}>
          <button className='add '>
            <Icon name='eye' />
            Voir
          </button>
        </Link>
        <Link to={`/agenda/randez-vous/${value?._id}`}>
          <button className='add black'>
            <Icon name='edit' />
            Gérer
          </button>
        </Link>
      </div>
    </div>
  );
}

export default FicheIntervention;
