import { popup } from '../../components/Popup/Popup';
import { queryClient } from '../../layouts/ReactQueryLayout';
import { Axios } from './axios';

const getReportings = () => {
  return Axios.get(`/report`).then(({ data }) => {
    console.log({ Reportings: data });
    return data;
  });
};

const createReporting = (data) => {
  return Axios.post(`/report`, data)
    .then(({ data }) => {
      console.log({ Reporting: data });
      queryClient.invalidateQueries('reportings');

      // popup.show({ title: 'Ajouter', type: 'notification' });
      return data;
    })
    .catch((err) => console.log(err));
};

const updateReporting = (data) => {
  console.log({ data });
  return Axios.put(`/report/${data?._id}`, {
    ...data,
    client: data?.client?._id,
  }).then(({ data }) => {
    console.log({ Reporting: data });
    queryClient.invalidateQueries('reportings');
    // popup.show({ title: 'Ajouter', type: 'notification' });
    return data;
  });
};

const getReporting = (id) => {
  return Axios.get(`/report/${id}`).then(({ data }) => {
    console.log({ Reportings: data });
    return data;
  });
};

export { getReportings, createReporting, updateReporting, getReporting };
