const fileType = (file) => {
  let type = '/images/';
  if (file?.toLowerCase()?.includes('xlsx')) type += 'file-exel.svg';
  else if (file?.toLowerCase()?.includes('.pdf')) type += 'file-pdf.svg';
  else if (file?.toLowerCase()?.includes('.docx')) type += 'file-word.svg';
  else type = file;

  return type;
};

export { fileType };
