import React, { useRef } from 'react';
import { useState } from 'react';
import Icon from '../../Icon';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  createCapture,
  getCapture,
  updateCapture,
} from '../../utils/api/capture';
import { createGoogleStreet } from '../../utils/api/googleStreet';
import Upload from '../../components/Upload/Upload';
import MapsSearch from '../../components/Maps/MapsSearch';

function AddGoogleStreet({ setShow }) {
  const [Title, setTitle] = useState('');
  const router = useNavigate();
  const { data: profile } = useQuery('profile');
  const { action, id } = useParams();
  const { data: capture } = useQuery('capture', () =>
    id !== 'ajouter' ? getCapture(id) : {}
  );
  const [value, setValue] = useState();
  const [position, setPosition] = useState({ lat: 34, lng: -6 });
  const CreateCapture = (data) => {
    createGoogleStreet({
      url: value,
      user: profile?._id,
      longitude: position?.lng,
      latitude: position?.lat,
    })
      .then((data) => router('/capture_service'))
      .catch((err) => console.log(err));
  };

  return (
    <div className='AddCapture'>
      <Link to={'/capture_service'} className='overlay'></Link>
      <div className='add-cap'>
        {/* <p>Insérez le lien</p>
        <input
          type='text'
          onChange={(e) => {
            let v = e.target.value;
            if (v.includes('http://') || v.includes('https://')) {
              setValue(v);
            }
          }}
          name=''
          value={value || capture?.url}
          placeholder='Tapez le lien'
          id=''
        /> */}
        <p>Insérez la position</p>
        <div className='flex'>
          <MapsSearch position={position} setPosition={setPosition} />
        </div>
        <p>où</p>

        <button>
          <Icon name='import-visit' />
          {!value ? 'Importer la visite' : 'fichier téléchargé avec succès'}
          <p id={`count-google`} style={{ margin: 0 }}></p>
          <Upload
            file_id='google'
            onUpload={(url) => setValue(url)}
            onStartUpload={(id, name, size, type) => setValue('')}
            isImage={false}
          />
        </button>
        <span>Importer les fichiers de type : ZIP</span>
        <span>
          Ajoutez votre entreprise sur la carte avec l'intégration Google Street
          View de MapLead. MapLead vous permet de publier facilement votre
          espace afin d'accroître sa visibilité.
        </span>
        <p>Google Street View</p>
        {/* <iframe src={'/street.png' || value} frameborder='0'></iframe> */}
        {value && <img className='iframe' src={value} alt='' />}
      </div>

      <button
        disabled={!value || !position?.lat || !position?.lng}
        onClick={CreateCapture}
      >
        <Icon name='checkbox' />
        Publiez en Google Street View
      </button>
    </div>
  );
}

export default AddGoogleStreet;
