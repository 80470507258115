import React from "react";
import Chart from "react-apexcharts";
import "./SemiCircleChart.css";
import Icon from "../../Icon/Icon";

const SemiCircleChart = () => {
  const options = {
    chart: {
      type: "donut",
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 90,
        customScale: 1,
        donut: {
          size: "85%",

          dataLabels: {
            enabled: false,
          },
          labels: {
            show: true,
            name: {
              show: true,
            },
            value: {
              show: true,
            },
            total: {
              show: true,
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    labels: ["Completed", "Cancelled", "Pending", "Failed"],
    legend: {
      show: false,
      offsetX: -30,
      offsetY: 70,
      formatter: (value, opts) => {
        return value + " - " + opts.w.globals.series[opts.seriesIndex];
      },
      markers: {
        onClick: undefined,
        offsetX: 0,
        offsetY: 25,
      },
    },
    fill: {
      type: "solid",
      colors: ["#377DFF", "#FF7A00", "#FFC900", "#000000"],
    },
    stroke: {
      width: 2,
      calors: ["#FFF"],
      lineCap: 'round',
    },
    colors: ["#377DFF", "#FF7A00", "#FFC900", "#000000"],
  };

  const series = [50, 20, 25, 15]; // Your data values

  return (
    <div className="semi-circle-chart">
      <div className="flex">
        <p>Performances</p>
        <Icon name="menu-dots" />
      </div>
      <Chart
        options={options}
        series={series}
        type="donut"
        height={200}
        width={"100%"}
      />
    </div>
  );
};

export default SemiCircleChart;
