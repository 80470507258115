import React, { useState } from 'react';
import './ReportingFile.css';
import Upload from '../../../../components/Upload/Upload';
import { array } from 'zod';
import Icon from '../../../../Icon';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getReporting, updateReporting } from '../../../../utils/api/reporting';
import { useForm } from 'react-hook-form';

function ReportingFile() {
  const { id } = useParams();

  const router = useNavigate();
  const { data: reporting } = useQuery(
    `reporting-${id}`,
    id ? () => getReporting(id) : () => {}
  );

  const {
    watch,
    setValue,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    values: reporting,
  });

  const setFiles = (value) => {
    setValue('uploads', value);
    console.log(value);
  };

  const updateFiles = (data) =>
    updateReporting({
      ...reporting,
      uploads: watch('uploads'),
    }).then((data) => data?._id && router(`/reporting/${data?._id}?step=3`));

  return (
    <div className='ReportingFile'>
      <div className='SelectDocument'>
        <Upload
          onStartUpload={(id, name, size, type) => {
            setFiles([...watch('uploads'), { id, name, size, type }]);
          }}
          onUpload={(url, id, name, size, type) => {
            let files = [...watch('uploads')];
            let Index = watch('uploads')?.findIndex((file) => file?.id === id);
            if (Index >= 0) {
              files[Index] = { id, name, size, type, url };
              setFiles(files);
            }
          }}
          onError={(id) => {
            let files = [...watch('uploads')];
            let Index = watch('uploads')?.findIndex((file) => file?.id === id);

            if (Index >= 0) {
              files.splice(Index, 1);
              setFiles(files);
            }
          }}
          isImage={false}
        />
        <img src='/images/upload-file.svg' />
        <h3>Importer votre documents ici</h3>
        <p>Taille de fichier maximale: 60 MB</p>
        <p>Format supporté: XLSx, PDF , DOCx. </p>
      </div>
      {watch('uploads')
        ?.map((file, i) => ({ ...file, index: i }))
        ?.sort((a, b) => b?.index - a?.index)
        ?.map((file, i) => (
          <div key={i} className='upload-progress'>
            <div className='flex'>
              <img
                src={
                  '/images/' +
                  (file?.name?.toLowerCase()?.includes('xlsx')
                    ? 'file-exel.svg'
                    : file?.name?.toLowerCase()?.includes('.pdf')
                    ? 'file-pdf.svg'
                    : 'file-word.svg')
                }
                alt=''
                srcset=''
              />
              <div>
                <h4>{file?.name}</h4>
                <p>
                  {(file?.size / 1000000).toFixed(2)} Mb Temps restant: 35 Min
                </p>
              </div>
              {file?.url ? (
                <Link
                  style={{
                    marginLeft: 'auto',
                  }}
                  to={file?.url}
                  download={true}
                >
                  <button
                    className='add'
                    style={{
                      height: 35,
                      fontSize: 15,
                      width: 120,
                    }}
                  >
                    Télécharger
                  </button>
                </Link>
              ) : (
                <button
                  className='close'
                  onClick={() => {
                    let files = [...watch('uploads')];
                    let Index = watch('uploads')?.findIndex(
                      (f) => f?.id === file?.id
                    );

                    if (Index >= 0) {
                      files.splice(Index, 1);
                      setFiles(files);
                    }
                  }}
                >
                  <Icon name='cross' />
                </button>
              )}
            </div>
            <div className='flex' style={{ gap: 10, marginTop: 20 }}>
              <div className='progress'>
                <div
                  className={file?.url ? 'full' : ''}
                  id={`id-` + file?.id}
                ></div>
              </div>
              <p id={`count-` + file?.id} style={{ margin: 0 }}>
                {file?.url ? 100 : 0}%
              </p>
            </div>
          </div>
        ))}
      <button className='add' onClick={updateFiles}>
        Suivant
      </button>
    </div>
  );
}

export default ReportingFile;
