import React from 'react';
import {
  getItemsByDay,
  getItemsByMontly,
  getItemsByWeek,
} from '../../utils/dataWekly';
import { useQuery } from 'react-query';
import { getAgendas } from '../../utils/api/agenda';
import Icon from '../../Icon';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

function SelectDateType() {
  const { data: agendas } = useQuery(`agendas`, getAgendas);
  const { type } = useParams();
  const thisWeek = getItemsByWeek(agendas, 0)?.length;
  const lastWeek = getItemsByWeek(agendas, 1)?.length;
  const this_month = getItemsByMontly(agendas)?.length;
  const this_day = getItemsByDay(agendas)?.length;
  const router = useNavigate();
  return (
    <div className='flex-top'>
      <div
        onClick={() => router('/agenda/randez-vous')}
        className={type !== 'event' ? 'active' : ''}
      >
        <p>Gestion de randez-vous</p>
        <h2>{thisWeek} Nouveau randez-vous</h2>
        <span>
          {lastWeek > 0
            ? ((100 * (thisWeek - lastWeek)) / lastWeek)?.toFixed(0)
            : 100}
          % <Icon name='caret-up' /> par rapport la semaine précédente
        </span>
        <button>
          <Icon name='layers' />
        </button>
      </div>
      <div
        onClick={() => router('/agenda/event')}
        className={type === 'event' ? 'active' : ''}
      >
        <p>Gestion de calendrier</p>
        <h2>
          {this_month} événement en {moment().format('MMMM')}
        </h2>
        <span>
          <Icon name='caret-up' /> +{this_day} événement aujourd’hui
        </span>
        <button>
          <Icon name='calendar' />
        </button>
      </div>
    </div>
  );
}

export default SelectDateType;
