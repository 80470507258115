import { queryClient } from '../../layouts/ReactQueryLayout';
import { Axios } from './axios';

const affichertousDocuments = () => {
  return Axios.get('/documents/').then((response) => {
    console.log({ documents: response.data });
    return response.data;
  });
};

const ajouterDocument = (documentData) => {
  return Axios.post('/documents/', documentData).then((response) => {
    console.log(response?.data);
    return response.data;
  });
};
const afficherDocument = (documentId) => {
  return Axios.get(`/documents/${documentId}`).then((response) => {
    console.log({ document: response.data });
    return response.data;
  });
};

const modifierDocument = (documentId, updatedDocumentData) => {
  return Axios.put(`/documents/${documentId}`, updatedDocumentData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Error updating document:', error);
      throw new Error('Error updating document');
    });
};

const supprimerDocument = (documentId) => {
  return Axios.delete(`/documents/${documentId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Error deleting document:', error);
      throw new Error('Error deleting document');
    });
};
const afficherTousFicheIntervention = () => {
  return Axios.get(`/documents/fiche-maintenance`).then((response) => {
    console.log({ 'fiche-maintenance': response.data });
    return response.data;
  });
};
const afficherFicheIntervention = (ficheInterventionId) => {
  return Axios.get(`/fiche_intervention/${ficheInterventionId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Error fetching fiche intervention:', error);
      throw new Error('Error fetching fiche intervention');
    });
};

const ajouterFicheIntervention = (ficheInterventionData) => {
  return Axios.post(
    '/documents/fiche-maintenance/',
    ficheInterventionData
  ).then((response) => {
    return response.data;
  });
};

const modifierFicheIntervention = (
  ficheInterventionId,
  updatedFicheInterventionData
) => {
  return Axios.put(
    `/documents/fiche-maintenance/${ficheInterventionId}`,
    updatedFicheInterventionData
  ).then((response) => {
    queryClient.invalidateQueries(`document/${ficheInterventionId}`);
    return response.data;
  });
};

const supprimerFicheIntervention = (ficheInterventionId) => {
  return Axios.delete(`/fiche_intervention/${ficheInterventionId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Error deleting fiche intervention:', error);
      throw new Error('Error deleting fiche intervention');
    });
};
const afficherContratBail = (contratBailId) => {
  return Axios.get(`/contrats_commerciaux/${contratBailId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Error fetching contrat commercial:', error);
      throw new Error('Error fetching contrat commercial');
    });
};

const ajouterContratBail = (contratBailData) => {
  return Axios.post('/documents/contract-bail/', contratBailData).then(
    (response) => {
      return response.data;
    }
  );
};

const modifierContratBail = (contratBailId, updatedContratBailData) => {
  return Axios.put(
    `/contrats_commerciaux/${contratBailId}`,
    updatedContratBailData
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Error updating contrat commercial:', error);
      throw new Error('Error updating contrat commercial');
    });
};

const supprimerContratBail = (contratBailId) => {
  return Axios.delete(`/contrats_commerciaux/${contratBailId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Error deleting contrat commercial:', error);
      throw new Error('Error deleting contrat commercial');
    });
};
const afficherContratHabitable = (contratHabitableId) => {
  return Axios.get(`/contrats_habitables/${contratHabitableId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Error fetching contrat habitable:', error);
      throw new Error('Error fetching contrat habitable');
    });
};

const ajouterContratHabitable = (contratHabitableData) => {
  return Axios.post('/contrats_habitables/', contratHabitableData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Error adding contrat habitable:', error);
      throw new Error('Error adding contrat habitable');
    });
};

const modifierContratHabitable = (
  contratHabitableId,
  updatedContratHabitableData
) => {
  return Axios.put(
    `/contrats_habitables/${contratHabitableId}`,
    updatedContratHabitableData
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Error updating contrat habitable:', error);
      throw new Error('Error updating contrat habitable');
    });
};

const supprimerContratHabitable = (contratHabitableId) => {
  return Axios.delete(`/contrats_habitables/${contratHabitableId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Error deleting contrat habitable:', error);
      throw new Error('Error deleting contrat habitable');
    });
};
const afficherQuittanceLoyer = (paiementId) => {
  return Axios.get(`/quittance/${paiementId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Error fetching quittance loyer:', error);
      throw new Error('Error fetching quittance loyer');
    });
};

export {
  affichertousDocuments,
  ajouterDocument,
  afficherDocument,
  modifierDocument,
  supprimerDocument,
  afficherFicheIntervention,
  ajouterFicheIntervention,
  modifierFicheIntervention,
  supprimerFicheIntervention,
  afficherContratBail,
  ajouterContratBail,
  modifierContratBail,
  supprimerContratBail,
  afficherContratHabitable,
  ajouterContratHabitable,
  modifierContratHabitable,
  supprimerContratHabitable,
  afficherQuittanceLoyer,
  afficherTousFicheIntervention,
};
