import { popup } from '../../components/Popup/Popup';
import { queryClient } from '../../layouts/ReactQueryLayout';
import { Axios } from './axios';

const createGoogleStreet = (data) => {
  return Axios.post(`/sreetview`, data).then(({ data }) => {
    // queryClient.invalidateQueries('captures');
    popup.show({
      title: 'Ajouter',
      type: 'notification',
      message: 'Photo uploaded successfully',
      button_name: 'Aperçus',
      button_action: () => window.open(data?.shareLink),
    });
    return data;
  });
};

export { createGoogleStreet };
