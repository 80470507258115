import React from 'react';
import './ChatTab.css';
import { Link, useParams } from 'react-router-dom';

function ChatTab() {
  const tabs = ['tout', 'techno-commercial', 'admins'];
  const { type } = useParams();

  return (
    <div className='ChatTab'>
      {tabs?.map((t, i) => (
        <Link
          to={`/chat/${t}`}
          className={`tab ${
            type ? (type === t ? 'active' : '') : i === 0 ? 'active' : ''
          }`}
          key={t}
        >
          {t}
        </Link>
      ))}
    </div>
  );
}

export default ChatTab;
