import { Outlet } from 'react-router-dom';
import AppLayout from '../AppLayout';
import Clients from '../../screens/Clients/Clients';


const clientsRouter = {
  path: '/clients',
  element: (
    <AppLayout>
      <Outlet />
    </AppLayout>
  ),
  children: [
    {
      path: '',
      element: <Clients />,
    },
    {
      path: ':action',
      element: <Clients />,
    },
    {
      path: ':action/:id',
      element: <Clients />,
    },
   
  ],
};

export { clientsRouter };
