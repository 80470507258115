import React, { useRef } from 'react';
import { useState } from 'react';
import Icon from '../../Icon';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  createCapture,
  getCapture,
  updateCapture,
} from '../../utils/api/capture';

function AddCaptureService({ setShow }) {
  const [Title, setTitle] = useState('');
  const router = useNavigate();
  const { data: profile } = useQuery('profile');
  const { id } = useParams();
  const { data: capture } = useQuery('capture', () =>
    id !== 'ajouter' ? getCapture(id) : {}
  );
  const [value, setValue] = useState(
    capture?.url ||
      'https://my.matterport.com/show/?m=jm5WwEA3HUN&log=0&help=0&nt=0&play=0&qs=0&brand=1&dh=1&tour=1&gt=1&hr=1&mls=0&mt=1&tagNav=1&pin=1&portal=1&f=1&fp=1&nozoom=0&search=1&wh=1&kb=1&lp=0&title=0&tourcta=1&vr=1'
  );
  const [Name, setName] = useState();
  const CreateCapture = (data) => {
    (id === 'ajouter' ? createCapture : updateCapture)({
      url: value,
      name: Name,
      user: profile?._id,
      id: capture?._id,
    })
      .then((data) => router(`/capture_service/${data?._id}/google`))
      .catch((err) => console.log(err));
  };

  return (
    <div className='AddCapture'>
      <Link
        to={'/capture_service'}
        className='overlay'
        onClick={() => setShow(false)}
      ></Link>
      <div className='add-cap'>
        <p>Insérez le lien</p>
        <input
          type='text'
          onChange={(e) => {
            let v = e.target.value;
            if (v.includes('http://') || v.includes('https://')) {
              setValue(v);
            }
          }}
          name=''
          value={value || capture?.url}
          placeholder='Tapez le lien'
          id=''
        />
        <p>Insérez le Nom</p>
        <input
          type='text'
          onChange={(e) => {
            let v = e.target.value;
            setName(v);
          }}
          name=''
          value={Name || capture?.name}
          placeholder='Tapez le nom'
          id=''
        />
        <p>où</p>

        {/* <button>
          <Icon name='import-visit' />
          Importer la visite
        </button> */}
        <span>Importer les fichiers de type : ZIP</span>
        <p>Visualisation</p>
        <iframe src={value} frameborder='0'></iframe>
      </div>

      <button disabled={!value || !Name} onClick={CreateCapture}>
        <Icon name='checkbox' />
        Enregistrer
      </button>
    </div>
  );
}

export default AddCaptureService;
