import React from 'react';
import ReactApexChart from 'react-apexcharts';
import './Chart.css';

function Chart(dashboard) {
  const { monthlyTotals } = dashboard;
  const data = {
    series: [
      {
        name: 'Rachid MOUFAKKIR',
        data: [0, 100, 200, 500, 250, 300, 100],
        color: '#FFA800',
      },
    ],
    options: {
      chart: {
        height: 400,
        type: 'line',
        width: '100%',
        stacked: false,

        toolbar: {
          show: false,
        },
      },

      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          borderRadius: 5,
          borderRadiusOnAllStackedSeries: true,
        },
      },
      legend: {
        show: false,
      },
      toolbar: {
        show: false,
      },

      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 4,
      },

      fill: {
        type: 'solid',

        opacity: 0,
      },

      xaxis: {
        type: 'datetime',
        labels: {
          style: {
            colors: [
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
            ],
          },
          datetimeFormatter: {
            year: 'yyyy',
            month: 'MMM',
            day: 'dd MMM',
            hour: 'HH:mm',
          },
        },
        categories: [
          '2018-05-19T02:30:00.000Z',
          '2018-06-19T01:30:00.000Z',
          '2018-07-19T00:00:00.000Z',
          '2018-08-19T03:30:00.000Z',
          '2018-09-19T04:30:00.000Z',
          '2018-10-19T05:30:00.000Z',
          '2018-11-19T06:30:00.000Z',
        ],
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value;
          },
          style: {
            colors: [
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
            ],
          },
        },
      },
    },
  };

  return (
    <div className='chart'>
      <div className='flex'>
        <div>Niveau de commerciales</div>
      </div>

      <div className='chart-container'>
        <ReactApexChart
          options={data.options}
          series={data.series}
          type='area'
          width={'100%'}
          height={'100%'}
        />
      </div>
      <div className='flex'>
        <div></div>
        <p>
          <span style={{ background: '#FFA800' }}></span> Rachid MOUFAKKIR
        </p>
        <div></div>
      </div>
    </div>
  );
}

export default Chart;
