import React, { useState } from 'react';
import './Authentification.css';
import { Link } from 'react-router-dom';
import Icon from '../../components/Icon/Icon';
import { useForm } from 'react-hook-form';
import AuthInfo from '../../components/auth_info/AuthInfo';
import { registerUser } from '../../utils/api/auth';
function Register() {
  const [ShowPassword, setShowPassword] = useState(false);
  const [ShowConfirmPassword, setShowConfirmPassword] = useState(false);
  const [roles, setRoles] = useState([
    'Technicien',
    'Propriétaire',
    'Locataire',
  ]);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { role: 'Technicien' } });

  return (
    <div className='Login Register'>
      <AuthInfo login={false} />
      <div className='Form-container' style={{ maxWidth: '100%' }}>
        <form onSubmit={handleSubmit(registerUser)}>
          <div className='form-items'>
            <h2>Créer un compte</h2>
            <div className='inputs'>
              <div className='flex' style={{ gap: 30 }}>
                <div className='input-container'>
                  <label htmlFor=''>
                    {errors.last_name ? 'Nom is required' : 'Nom'}
                  </label>
                  <input
                    {...register('last_name', { required: true })}
                    type='text'
                    placeholder='Entrer votre Nom'
                  />
                </div>
                <div className='input-container'>
                  <label htmlFor=''>
                    {errors.first_name ? 'Prénom is required' : 'Prénom'}
                  </label>
                  <input
                    {...register('first_name', { required: true })}
                    type='text'
                    placeholder='Entrer votre Prénom'
                  />
                </div>
              </div>
              <div className='input-container'>
                <label htmlFor=''>
                  {errors.email ? 'Email is required' : 'Email'}
                </label>
                <input
                  {...register('email', { required: true })}
                  type='email'
                  placeholder='Entrer votre email'
                />
              </div>

              <div className='input-container'>
                <label htmlFor=''>
                  {errors.mot_de_passe
                    ? 'Mot de passe is required'
                    : 'Mot de passe'}
                </label>

                <input
                  {...register('password', { required: true })}
                  autoComplete={'new-password'}
                  type={ShowPassword ? 'text' : 'password'}
                  placeholder='Entrer votre mot de passe'
                />

                <div
                  className='show-password'
                  onClick={() => setShowPassword((v) => !v)}
                >
                  <Icon name={ShowPassword ? 'hide-eye' : 'eye'} />
                </div>
              </div>
              <div className='input-container'>
                <label htmlFor=''>
                  {errors.confirm_password
                    ? 'Confirmer le mot de passe is required'
                    : 'Confirmer le mot de passe'}
                </label>

                <input
                  {...register('confirm_password', {
                    required: true,
                  })}
                  className={
                    watch('password') !== watch('confirm_password')
                      ? 'error'
                      : ''
                  }
                  autoComplete={'new-password'}
                  type={ShowConfirmPassword ? 'text' : 'password'}
                  placeholder='Entrer votre mot de passe'
                />

                <div
                  className='show-password'
                  onClick={() => setShowConfirmPassword((v) => !v)}
                >
                  <Icon name={ShowConfirmPassword ? 'hide-eye' : 'eye'} />
                </div>
              </div>
            </div>
            <div className='buttons'>
              <button className='primary-color'>Créer un compte</button>
              {/* <button className='primary-color'>
                <Icon name='google' />
                Continuer avec Google
              </button> */}
              {/* <button className='primary-color'>
                
                <Icon name='facebook' />
                Continuer avec Facebook
              </button> */}
              <h4>
                Vous avez déjà un compte ?<Link to={'/'}>Se connecter</Link>
              </h4>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Register;
