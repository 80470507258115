import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { getUsers } from '../../utils/api/users';
import './SelectUser.css';

function SelectUser({
  register,
  errors,
  profile,
  watch,
  setValue,
  lable,
  name,
}) {
  const { data: users, error, isLoading } = useQuery('users', getUsers);

  useMemo(() => {
    const { first_name, last_name } =
      users?.find((o) => o?._id === watch(name)) || {};

    if (first_name) {
      setValue('user_name', `${first_name} ${last_name}`);
    }
  }, [watch(name)]);

  return (
    <select
      value={watch(name)}
      className=' SelectUser'
      onChange={(e) => setValue(name, e?.target?.value)}
      style={{ borderColor: errors?.[name] ? 'red' : '#e4e7eb' }}
    >
      <option selected disabled>
        {name}
      </option>
      {users?.map((l, i) => (
        <option key={i} value={l?._id}>
          {l?.first_name} {l?.last_name}
        </option>
      ))}
    </select>
  );
}

export default SelectUser;
