function getItemsByWeek(items, weekOffset) {
  const now = new Date();
  const startOfCurrentWeek = new Date(
    now.setDate(now.getDate() - now.getDay())
  ); // Start of this week (Sunday)

  // Calculate the start and end of the desired week
  const startOfTargetWeek = new Date(startOfCurrentWeek);
  startOfTargetWeek.setDate(startOfTargetWeek.getDate() - 7 * weekOffset);

  const endOfTargetWeek = new Date(startOfTargetWeek);
  endOfTargetWeek.setDate(endOfTargetWeek.getDate() + 7);

  return items?.filter((item) => {
    if (!item?.date) return false;

    const itemDate = new Date(item?.date);
    return itemDate >= startOfTargetWeek && itemDate < endOfTargetWeek;
  });
}

function getItemsByMontly(items) {
  const now = new Date();
  const current_year = now.getFullYear();
  const current_month = now.getMonth();

  return items?.filter(({ date }) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = d.getMonth();

    return year === current_year && month === current_month;
  });
}

function getItemsByDay(items) {
  const now = new Date();
  const current_year = now.getFullYear();
  const current_month = now.getMonth();
  const current_day = now.getDay();

  return items?.filter(({ date }) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = d.getMonth();
    const day = d.getDay();

    return (
      year === current_year && month === current_month && day === current_day
    );
  });
}

export { getItemsByWeek, getItemsByMontly, getItemsByDay };
