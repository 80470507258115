import React, { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import Calendar1 from 'react-calendar';
import moment from 'moment';
import 'moment/locale/fr';
import { eventPropGetter, events } from '../../../utils/events';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './CallCalendar.css';
import Icon from '../../../components/Icon/Icon';
import AddCalendar from './AddCalendar';
import { useQuery } from 'react-query';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import Calendar_Planifier from '../../../components/Calendar/Calendar';
import { getAgenda, getAgendas } from '../../../utils/api/agenda';

moment.locale('fr');
const localizer = momentLocalizer(moment);

export default function CallCalendar() {
  const { id, type } = useParams();
  const router = useNavigate();
  const [params, setParams] = useSearchParams();
  const date = params.get('date');
  const { data: visits } = useQuery(`agendas`, getAgendas);
  // const visits = eventsData;

  const soonVisit = visits
    ?.filter((v) => moment(v?.date)?.diff(moment(), 'days') >= 0)
    ?.sort((v1, v2) => moment(v1?.date)?.diff(moment(v2?.date), 'days'))?.[0];

  const handleSelect = ({ start, end }) => {
    let value = {
      date: moment(start || new Date()).format('YYYY-MM-DD'),
      startTime: moment(start || '2000/01/01 08:00').format('hh:mm'),
      endTime: moment(end || '2000/01/01 09:00').format('hh:mm'),
      type: start ? 'event' : 'randez-vous',
    };
    Object.keys(value).map((k) => params.set(k, value?.[k]));
    setParams(params);
  };

  return (
    <div className='CallCalendar'>
      {(id || date) && <AddCalendar />}
      <div className='left-call'>
        <button className='add' onClick={handleSelect}>
          <Icon name='add' />
          Ajouter un Rendez-vous
        </button>
        {/* <Calendar1
       
       value={new Date()}
       nextLabel={<Icon name='arrow-right' />}
       prevLabel={<Icon name='arrow-left' />}
      
     /> */}
        {soonVisit && (
          <div className='agenda-container'>
            <div className='flex'>
              <img src='/images/agenda.png' alt='' />
              <div>
                <span>Bientôt- Visite Appart...e</span>
                <p>
                  <span></span>
                  {/* {visits?.[0]?.message} */}
                  {soonVisit?.title}
                </p>
              </div>
            </div>
            <div className='line'></div>
            <div className='flex time'>
              {soonVisit?.startTime}
              <Icon name='from-to' />
              {soonVisit?.endTime}
            </div>
            <button onClick={() => handleSelect({ start: new Date() })}>
              <Icon name='video-camera' />
              <Icon name='phone-call' />
              Ajouter un évenement
            </button>
          </div>
        )}
      </div>
      <Calendar
        views={['week', 'day', 'month']}
        messages={{
          next: '',
          previous: '',
          month: 'Mois',
          week: 'Semaine',
          day: 'Jour',
        }}
        selectable
        localizer={localizer}
        defaultDate={new Date()}
        defaultView='week'
        min={new Date(2020, 1, 0, 7, 0, 0)}
        max={new Date(2020, 1, 0, 19, 0, 0)}
        events={visits
          ?.filter((v) => v?.date && v?.startTime && v?.endTime)
          ?.filter((v) => v?.type === type)
          ?.map((v, i) => ({
            id: v?._id,
            title: v?.title,
            // allDay: true,
            start: new Date(
              `${moment(v?.date).format('YYYY-MM-DD')} ${v?.startTime}:00`
            ),
            end: new Date(
              `${moment(v?.date).format('YYYY-MM-DD')} ${v?.endTime}:00`
            ),
          }))}
        style={{ height: '100vh' }}
        onSelectEvent={(event) => {
          router(`/agenda/${type}/${event?.id}`);
        }}
        onSelectSlot={handleSelect}
        eventPropGetter={eventPropGetter}
      />
    </div>
  );
}
