import React from 'react';
import './Counts.css';
import Icon from '../../Icon/Icon';

function Counts({ dashboard = {}, isLoading = false }) {
  const { Revenu, Commandes, utilisateurs } = dashboard;

  if (isLoading)
    return (
      <div className='LoadingAnime'>
        <div className='counts'>
          {[1, 1, 1, 1].map((_, i) => (
            <div className='count' key={i}>
              <div className='icon anime'></div>
              <div className='data'>
                <h5 className='anime'></h5>
                <span className='anime'></span>
              </div>
            </div>
          ))}
        </div>
      </div>
    );

  return (
    <div className='chart'>
      <div style={{ width: 'fit-content' }}>
        <div className='flex'>
          <div>
            Ventes du mois
            <p>Aperçu de ventes</p>
          </div>

          <button>
            <Icon name='upload' />
            Télécharger
          </button>
        </div>
        <div className='counts'>
          <div className='count'>
            <Icon name='dashboard-bg' />
            <div className='data'>
              <h5> {Revenu / 1000}k MAD</h5>
              <p>Total Sales</p>
              <span>+8% depuis hier</span>
            </div>
          </div>
          <div className='count'>
            <Icon name='file-bg' />
            <div className='data'>
              <h5>{Commandes} </h5>
              <p>Commandes</p>
              <span>+8% depuis hier</span>
            </div>
          </div>
          <div className='count'>
            <Icon name='tag' />
            <div className='data'>
              <h5> 5</h5>
              <p>Produits vendus</p>
              <span>+8% depuis hier</span>
            </div>
          </div>
          <div className='count'>
            <Icon name='add-user' />
            <div className='data'>
              <h5>{utilisateurs}</h5>
              <p>Nouveau Clients</p>
              <span>+8% depuis hier</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Counts;
