import React, { useState } from 'react';
import Icon from '../../components/Icon/Icon';
import './Clients.css';
import RightUser from './RightUser/RightUser';
import { useQuery } from 'react-query';
import { getClients } from '../../utils/api/client';
import { Link, useParams } from 'react-router-dom';
import Table from '../../components/Table/Table';
import { getUsers } from '../../utils/api/users';

function Clients() {
  const keys = ['Photograph', 'Utilisateur', 'Email', 'Téléphone'];
  const { data: users, error, isLoading } = useQuery('clients', getClients);
  const { data: user } = useQuery('profile');
  const { id, action } = useParams();
  return (
    <div className='Avatar'>
      {/* <button type='button' className='add' onClick={() => setShowAvatar(true)}>
        <Icon name={'add-cercle'} /> 
      </button> */}

      <Table
        keys={keys}
        isLoading={isLoading}
        list={users?.filter((u) => u?._id !== user?._id)}
        // show_action={user?.role === 'Admin'}
        button={'Ajouter un client'}
        url='clients'
      >
        {({ List }) =>
          List?.map((client, index) => (
            <tr key={index}>
              <td>{client?._id}</td>
              <td>
                <Link to={`/clients/edit/${client?._id}`}>
                  <div className='avatar'>
                    <img
                      loading='lazy'
                      src={client?.profileImg || '/images/user.png'}
                      alt=''
                    />
                    <div>
                      <h5>{`${client?.nom} `}</h5>
                    </div>
                  </div>
                </Link>
              </td>
              <td>{client?.email}</td>
              <td>{client?.telephone}</td>
              <td>
                <div className='flex '>
                  <Link
                    style={{ background: '#0000' }}
                    to={`/clients/edit/${client?._id}`}
                  >
                    {' '}
                    <Icon name='edit' />{' '}
                  </Link>

                  <Link
                    style={{ background: '#0000' }}
                    to={`/clients/view/${client?._id}`}
                  >
                    <Icon name='eye' />
                  </Link>
                </div>
              </td>
            </tr>
          ))
        }
      </Table>
      {action && <RightUser />}
    </div>
  );
}

export default Clients;
