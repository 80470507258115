import React, { useState } from 'react';
import './ProfileHeader.css';
import Icon from '../Icon/Icon';
import { logout } from '../../utils/api/auth';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';

function ProfileHeader() {
  const [Show, setShow] = useState(false);
  const { data: user } = useQuery('profile');
  return (
    <div className='ProfileHeader'>
      <button className='user' onClick={() => setShow(true)}>
        <img
          loading='lazy'
          src={user?.profilePicture ||  '/images/user.png'}
        />
        <Icon name='angle-small-down' />
      </button>
      {Show && (
        <>
          <div className='overlay' onClick={() => setShow(false)}></div>
          <div className='list'>
            <Link to={`/profile`}>
              <img
                loading='lazy'
                src={user?.profilePicture || '/images/user.png'}
              />{' '}
              {`${user?.first_name} ${user?.last_name}`}
            </Link>
            {/*   <Link to={`/profile`}>
              <Icon name='edit' />
              Modifier
            </Link> */}
            {/* <button>
              <Icon name='key' />
              Mot de passe
            </button> */}
            <Link to={`/profile`}>
              <Icon name='settings' />
              Paramètres
            </Link>
            <button onClick={logout}>
            <Icon name='sign-out' />
              Se déconnecter
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default ProfileHeader;
