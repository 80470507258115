import axios from 'axios';
import { toast } from 'react-hot-toast';
import { queryClient } from '../../layouts/ReactQueryLayout';
import { Roles } from '../getRoleRoutes';

// const baseURL = 'https://app.vrboostagency.com/api/';
const baseURL = 'https://hello.vrboostagency.com/api/';
// const baseURL = 'http://localhost:8000/api/';
var config = {
  onUploadProgress: function (progressEvent) {
    var percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );

    console.log(percentCompleted);
  },
};

const Axios = axios.create({
  // baseURL: 'https://boost-backend-six.vercel.app/api/',
  // baseURL: 'http://54.234.209.222:8000/api/',
  baseURL,
});

Axios?.interceptors?.request?.use(async (config) => {
  const publicUrls = ['auth'];
  const isPublicUrl = publicUrls?.every((url) => config?.url?.includes(url));
  const token = localStorage.getItem('token');
  let user = JSON.parse(localStorage.getItem('user') || '{}');
  if (token && !isPublicUrl) {
    config.headers['Authorization'] = `Bearer ${token}`;

    config.baseURL = `${baseURL}${Roles[user?.role || 'Technicien']}/${
      user?._id
    }/`;
  }

  if (config?.method !== 'get') {
    toast.loading('loading', {
      id: config?.url,
    });
  }

  return config;
});

Axios.interceptors.response.use(
  (response) => {
    Toast_Success(response);
    return response;
  },
  (error) => {
    Toast_Errors(error);
    return Promise.reject(error);
  }
);

// Show message alert
// success

const Toast_Success = (response) => {
  let message = '';
  if (typeof response?.data?.message === 'string')
    message = response?.data?.message;
  else if (
    typeof response?.data === 'string' &&
    !response?.data?.includes('!DOCTYPE')
  )
    message = response?.data;
  else if (typeof response?.data?.data === 'string')
    message = response?.data?.data;
  else if (typeof response?.data?.data?.message === 'string')
    message = response?.data?.data?.message;
  else toast?.remove(response?.config?.url);

  if (message && response?.config?.method !== 'get')
    toast.success(message, {
      id: response?.config?.url,
    });
};
// errors
const Toast_Errors = (error) => {
  console.log(error);
  let message = '';
  if (error?.response?.status === 401) {
    if (localStorage.getItem('token')) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      queryClient.invalidateQueries(['token']);
      queryClient.invalidateQueries(['user']);
    }
    // if (!window.location.href.includes('/auth/login'))
    //   return (window.location.href = '/auth/login');
  } else if (
    error?.response?.data &&
    typeof error?.response?.data === 'string' &&
    !error?.response?.data?.includes('!DOCTYPE')
  ) {
    message = error?.response?.data;
  } else if (
    error?.response?.data?.msg?.message &&
    typeof error?.response?.data?.msg?.message === 'string'
  ) {
    message = error?.response?.data?.msg?.message;
  } else if (
    error?.response?.data?.error &&
    typeof error?.response?.data?.error === 'string'
  ) {
    message = error?.response?.data?.error;
  } else if (
    error?.response?.data?.message &&
    typeof error?.response?.data?.message === 'string'
  ) {
    message = error?.response?.data?.message;
  } else if (error?.message && typeof error?.message === 'string')
    message = error?.message;
  else if (typeof error?.response === 'string') message = error?.response;
  else if (error?.config?.method !== 'get') message = error?.response?.status;
  else toast?.remove(error?.config?.url);

  if (message) toast.error(message, { id: error?.config?.url });
};

export { Axios, baseURL };
