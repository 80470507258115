import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import './SelectUser.css';
import { getClients } from '../../utils/api/client';

function SelectClient({
  register,
  errors,
  profile,
  watch,
  setValue,
  lable,
  name,
  required = false,
}) {
  const { data: users, error, isLoading } = useQuery('clients', getClients);

  // useMemo(() => {
  //   const { first_name, last_name } =
  //     users?.find((o) => o?._id === watch(name)) || {};

  //   if (first_name) setValue('user_name', `${first_name} ${last_name}`);
  // }, [watch(name)]);

  return (
    <select
      value={watch(name)}
      onChange={(e) => setValue(name, e?.target?.value)}
      required={required}
    >
      <option selected disabled>
        {lable}
      </option>
      {users?.map((l, i) => (
        <option key={i} value={l?._id}>
          {l?.nom}
        </option>
      ))}
    </select>
  );
}

export default SelectClient;
