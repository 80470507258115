import { Outlet } from 'react-router-dom';
import AppLayout from '../AppLayout';
import Chat from '../../screens/Chat/Chat';
import Descution from '../../screens/Chat/Descution/Descution';

const chatRouter = {
  path: '/chat',
  element: (
    <AppLayout>
      <Outlet />
    </AppLayout>
  ),
  children: [
    {
      path: '',
      element: <Chat />,
    },
    {
      path: 'discution',
      element: <Descution />,
    },
    {
      path: 'discution/:id',
      element: <Descution />,
    },
    {
      path: ':type',
      element: <Chat />,
    },
  ],
};

export { chatRouter };
