import React, { Fragment, useEffect, useState } from 'react';
import './Dashboard.css';
import Counts from '../../components/Dashboard/Counts/Counts';
import Chart from '../../components/Dashboard/Chart/Chart';
import { useQuery, useQueryClient } from 'react-query';
import ChartBar from '../../components/Dashboard/Chart/ChartBar';
import UserCounts from '../../components/Dashboard/UserCounts';
import SelectUser from '../../components/SelectUsers/SelectUser';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { getUser } from '../../utils/api/users';
import { queryClient } from '../../layouts/ReactQueryLayout';
import { getDashboard, getDashboardTechno } from '../../utils/api/dashboard';
import CaptureService from '../CaptureService/CaptureService';
function DashboardTechno() {
  const { data: profile } = useQuery('profile');
  const { id } = useParams();
  // const { data: properties, isLoading } = useQuery("properties", getProperties);
  const { data: dashboard, isLoading: isLoadingDash } = useQuery(
    'dashboard',
    getDashboard
  );
  const router = useNavigate();
  // const { register, errors, watch, setValue } = useForm({
  //   defaultValues: { user: id },
  // });

  return (
    <div className='Dashboard '>
      {/* <SelectUser
        {...{
          register,
          errors,
          profile,
          watch,
          setValue,
          lable: '',
          name: 'user',
        }}
      />
      <UserCounts /> */}
      <div className='grid-dash'>
        <Counts dashboard={dashboard} />
        <Chart />
        <ChartBar />
      </div>
      <CaptureService />
    </div>
  );
}

export default DashboardTechno;
