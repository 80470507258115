import { popup } from '../../components/Popup/Popup';
import { queryClient } from '../../layouts/ReactQueryLayout';
import { Axios } from './axios';

const createCapture = (data) => {
  return Axios.post(`/capture-service`, data).then(({ data }) => {
    queryClient.invalidateQueries('captures');
    popup.show({ title: 'Ajouter', type: 'notification' });
    return data;
  });
};

const getAllCaptures = () => {
  return Axios.get(`/capture-service`).then(({ data }) => {
    console.log({ captures: data });
    return data;
  });
};

const getCapture = (id) => {
  return Axios.get(`/capture-service/${id}`).then(({ data }) => {
    console.log({ capture: data });
    return data;
  });
};

const updateCapture = (data) => {
  return Axios.put(`/capture-service/${data?.id}`, data).then(({ data }) => {
    queryClient.invalidateQueries('captures');

    return data;
  });
};

const deleteCapture = (id) => {
  return Axios.delete(`/capture-service/${id}`).then(({ data }) => {
    queryClient.invalidateQueries('captures');
    return data;
  });
};

export {
  createCapture,
  getAllCaptures,
  getCapture,
  updateCapture,
  deleteCapture,
};
