import React, { useEffect, useState } from 'react';
import './Users.css';

import Table from '../../components/Table/Table';
import { Link } from 'react-router-dom';
import { popup } from '../../components/Popup/Popup';
import Icon from '../../components/Icon/Icon';
import { useQuery } from 'react-query';
import { deleteUser, getUsers } from '../../utils/api/users';
import { queryClient } from '../../layouts/ReactQueryLayout';

function Users() {
  const keys = ['Photograph', 'Utilisateur', 'Email', 'Téléphone'];
  const { data: user } = useQuery('profile');
  const {
    data: users,
    error,
    isLoading,
  } = useQuery('users', user?.role === 'Admin' ? () => getUsers() : () => {});

  const DeleteUser = (id) => {
    deleteUser({ id }).then((d) => queryClient.invalidateQueries('users'));
  };

  return (
    <div className='Users'>
      <Table
        keys={keys}
        isLoading={isLoading}
        list={users?.filter((u) => u?._id !== user?._id)}
        show_action={user?.role === 'Admin'}
      >
        {({ List }) =>
          List?.map((utilisateur, index) => (
            <tr key={index}>
              <td>{utilisateur?._id}</td>
              <td>
                <Link to={`/utilisateurs/modifier/${utilisateur?._id}`}>
                  <div className='avatar'>
                    <img
                      loading='lazy'
                      src={utilisateur?.profilePicture || '/images/user.png'}
                      alt=''
                    />
                    <div>
                      <h5>{`${utilisateur?.first_name} ${utilisateur?.last_name}`}</h5>
                    </div>
                  </div>
                </Link>
              </td>
              <td>{utilisateur?.email}</td>
              <td>{utilisateur?.phone}</td>
              {user?.role === 'Admin' && (
                <td>
                  <div className='flex '>
                    <Link
                      style={{ background: '#0000' }}
                      to={`/utilisateurs/modifier/${utilisateur?._id}`}
                    >
                      {' '}
                      <Icon name='edit' />{' '}
                    </Link>

                    <Link
                      style={{ background: '#0000' }}
                      to={`/dashboard/${utilisateur?._id}`}
                    >
                      <Icon name='eye' />
                    </Link>
                  </div>
                </td>
              )}
            </tr>
          ))
        }
      </Table>
    </div>
  );
}

export default Users;
