import React from 'react';
import './InformationForm.css';
import Icon from '../../../../Icon';
import { useForm } from 'react-hook-form';
import SelectUser from '../../../../components/SelectUsers/SelectUser';
import { zodResolver } from '@hookform/resolvers/zod';
import { ReportingSchema } from '../../../../utils/zod_check';
import {
  createReporting,
  getReporting,
  updateReporting,
} from '../../../../utils/api/reporting';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import moment from 'moment';
import { getProfile } from '../../../../utils/api/users';

function InformationForm() {
  const { id } = useParams();

  const { data: profle } = useQuery('profile', getProfile);
  const { data: reporting } = useQuery(
    `reporting-${id}`,
    id ? () => getReporting(id) : () => ({})
  );
  const {
    watch,
    setValue,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(ReportingSchema),
    values: reporting,
  });

  const donnes = [
    'Base de donnés',
    'Systèmes de suivi',
    'Collection de données ( Entretien, Questionnaire, Focus group...)',
  ];

  const router = useNavigate();

  console.log(errors);
  return (
    <form
      className='InformationForm'
      onSubmit={handleSubmit(
        !id
          ? (data) =>
              createReporting(data).then(
                (data) => data?._id && router(`/reporting/${data?._id}?step=2`)
              )
          : (data) =>
              updateReporting(data).then(
                (data) => data?._id && router(`/reporting/${data?._id}?step=2`)
              )
      )}
    >
      <section>
        <h3>Information Generales</h3>
        <div className='input-container'>
          <label htmlFor=''> Titre de rapport</label>
          <input
            {...register('title')}
            style={{ borderColor: errors?.['title'] ? 'red' : '#e4e7eb' }}
            type='text'
            placeholder='Rapport financier'
          />
        </div>
        <div className='input-container'>
          <label htmlFor=''>Description</label>
          <textarea
            {...register('description')}
            style={{ borderColor: errors?.['description'] ? 'red' : '#e4e7eb' }}
            cols='30'
            rows='10'
            placeholder='Tapez votre description ici'
          ></textarea>
        </div>
      </section>

      <div className='flex' style={{ gap: 30 }}>
        {profle?.role === 'Admin' ? (
          <section>
            <h3>Auteur</h3>
            <div className='input-container'>
              <label htmlFor=''> Nom et Prénom</label>
              <SelectUser
                {...{
                  register,
                  watch,
                  setValue,
                  errors,
                  name: 'user',
                  label: 'Nom et Prénom',
                }}
              />
            </div>
          </section>
        ) : (
          <>
            <input
              type='hidden'
              name=''
              value={profle?._id}
              {...register('user')}
            />
            <input
              type='hidden'
              name=''
              value={profle?.first_name + ' ' + profle?.last_name}
              {...register('user_name')}
            />
          </>
        )}
        <section>
          <h3>Période couverte</h3>
          <div className='flex' style={{ gap: 14 }}>
            <div className='input-container'>
              <label htmlFor=''> Date de début</label>
              <input
                type='date'
                {...register('startDate')}
                value={moment(watch('startDate'))?.format('YYYY-MM-DD')}
                style={{
                  borderColor: errors?.['startDate'] ? 'red' : '#e4e7eb',
                }}
                placeholder='Date de début'
              />
            </div>
            <div className='input-container'>
              <label htmlFor=''> Date de fin</label>
              <input
                type='date'
                {...register('endDate')}
                value={moment(watch('endDate'))?.format('YYYY-MM-DD')}
                style={{ borderColor: errors?.['endDate'] ? 'red' : '#e4e7eb' }}
                placeholder='Date de début'
              />
            </div>
          </div>
        </section>
      </div>
      <section>
        <h3>Objectif</h3>

        <div className='input-container'>
          <label htmlFor=''>Objectif</label>
          <textarea
            {...register('objectif')}
            style={{ borderColor: errors?.['objectif'] ? 'red' : '#e4e7eb' }}
            cols='30'
            rows='10'
            placeholder='Tapez votre Objectif ici'
          ></textarea>
        </div>
      </section>
      <section>
        <h3>Sources de Données</h3>

        <div className='input-container'>
          <label htmlFor=''>Variation Type</label>
          <ul>
            {donnes?.map((item, i) => (
              <li
                key={i}
                onClick={() => {
                  let items = [...(watch('donnes') || [])];
                  let Index = items?.findIndex((it) => it === item);
                  console.log(Index);
                  if (Index >= 0) {
                    items.splice(Index, 1);
                    setValue('donnes', items);
                  } else {
                    setValue('donnes', [...items, item]);
                  }
                }}
              >
                <button
                  type='button'
                  className={watch('donnes')?.includes(item) ? 'active' : ''}
                >
                  <Icon name='check' />
                </button>
                <label htmlFor='base'>{item}</label>
              </li>
            ))}
            <button type='button'>
              <Icon name='add' />
              Ajouter une source
            </button>
          </ul>
        </div>
      </section>
      <section>
        <h3>Commentaire</h3>

        <div className='input-container'>
          <label htmlFor=''>Commentaire</label>
          <textarea
            {...register('commentaire')}
            style={{ borderColor: errors?.['commentaire'] ? 'red' : '#e4e7eb' }}
            cols='30'
            rows='10'
            placeholder='Tapez votre Commentaire ici'
          ></textarea>
        </div>
      </section>

      <button className='add'>Suivant</button>
    </form>
  );
}

export default InformationForm;
