import Dashboard from '../../screens/Dashboard/Dashboard';
import DashboardTechno from '../../screens/Dashboard/DashboardTechno';
import AppLayout from '../AppLayout';

const dashboardRouter = {
  path: '/',
  element: (
    <AppLayout>
      <DashboardTechno />
    </AppLayout>
  ),
};

export { dashboardRouter };
