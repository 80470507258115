import React, { useState } from 'react';
import Icon from '../../components/Icon/Icon';
import './Reporting.css';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import Table from '../../components/Table/Table';
import { getUsers } from '../../utils/api/users';
import { getReportings } from '../../utils/api/reporting';
import moment from 'moment';

function Reporting() {
  const [ShowAvatar, setShowAvatar] = useState(false);
  const [SelectedAvatar, setSelectedAvatar] = useState(null);

  const keys = ['Rapports', 'Effectuée par', 'Date', 'Télécharger'];
  const {
    data: reportings,
    error,
    isLoading,
  } = useQuery('reportings', getReportings);
  const { data: user } = useQuery('profile');

  return (
    <div className='Avatar'>
      {/* <button type='button' className='add' onClick={() => setShowAvatar(true)}>
        <Icon name={'add-cercle'} /> 
      </button> */}

      <Table
        keys={keys}
        isLoading={isLoading}
        list={reportings}
        // show_action={user?.role === "Admin"}
        button={'Ajouter un Rapport'}
        url='reporting'
        design={true}
        show_search={false}
      >
        {({ List }) =>
          List?.map((item, index) => (
            <tr key={index}>
              <td>
                <div>
                  <img
                    src={
                      '/images/' +
                      (item?.uploads[0]?.name?.toLowerCase()?.includes('xlsx')
                        ? 'file-exel.svg'
                        : item?.uploads[0]?.name
                            ?.toLowerCase()
                            ?.includes('.pdf')
                        ? 'file-pdf.svg'
                        : 'file-word.svg')
                    }
                    alt=''
                  />{' '}
                  {item?.title}
                </div>
              </td>
              <td>
                <div>{item?.user}</div>
              </td>
              <td>
                <div>{moment(item?.startDate)?.format('DD/MM/YYYY')}</div>
              </td>
              <td>
                {' '}
                <div>
                  {item?.uploads[0]?.url && (
                    <Link
                      style={{
                        marginLeft: 'auto',
                      }}
                      to={item?.uploads[0]?.url}
                      download={true}
                    >
                      <button>Télécharger</button>
                    </Link>
                  )}
                </div>
              </td>
              {user?.role === 'Admin' && (
                <td>
                  <div className='flex '>
                    <Link
                      style={{ background: '#0000' }}
                      to={`/reporting/${item?._id}`}
                    >
                      {' '}
                      <Icon name='trash' />{' '}
                    </Link>

                    <Link
                      style={{ background: '#0000' }}
                      to={`/reporting/${item?._id}`}
                    >
                      <Icon name='eye' />
                    </Link>
                  </div>
                </td>
              )}
            </tr>
          ))
        }
      </Table>
    </div>
  );
}

export default Reporting;
