import React, { useMemo } from 'react';
import './Search.css';
import Icon from '../Icon/Icon';

function Search({ getList = () => {}, list }) {
  const filter_data = (v) => {
    return list?.filter((o) =>
      Object.values(o)?.some((item) =>
        item?.toString()?.toLowerCase()?.includes(v?.toLowerCase())
      )
    );
  };

  return (
    <div className='Search'>
      <Icon name='search' />
      <input
        type='text'
        onChange={(e) => {
          let v = e.target.value;
          getList(v ? filter_data(v) : list);
        }}
        placeholder='Rechercher'
        name=''
        id=''
      />
    </div>
  );
}

export default Search;
