import React from 'react';
import './VentesAnnuelles.css';
import ReactApexChart from 'react-apexcharts';

function VentesAnnuelles({ dashboard = {} }) {
  const values = dashboard?.monthlyTotals?.map((item) => item?.total) || [];

  const series = [
    {
      name: 'Marine Sprite',
      data: values,
      color: '#E0FF22',
    },
    {
      name: 'Striking Calf',
      data: values.map((v) => 10000 - v),
      color: '#FFFFFF',
    },
  ];
  const data = {
    series,
    options: {
      chart: {
        type: 'bar',
        height: 200,
        stackType: '100%',
        stacked: true,

        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 5,
          borderRadiusOnAllStackedSeries: true,
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff'],
      },

      fill: {
        opacity: 1,
      },
      legend: {
        show: false,
      },
      toolbar: {
        show: false,
      },
      stroke: {
        curve: 'smooth',
        width: 0,
      },

      dataLabels: {
        enabled: false,
      },
      grid: {
        show: false,
      },
      yaxis: {
        labels: {
          show: false, // Show or hide y-axis labels
        },
        axisBorder: {
          show: false, // Show or hide y-axis border
        },
        axisTicks: {
          show: false, // Show or hide y-axis ticks
        },
        title: {
          show: false,
        },
      },
      xaxis: {
        categories: [
          'J',
          'F',
          'M',
          'A',
          'M',
          'J',
          'J',
          'A',
          'S',
          'O',
          'N',
          'D',
        ],
        labels: {
          show: true, // Show or hide y-axis labels
        },

        axisBorder: {
          show: false, // Show or hide y-axis border
        },
        axisTicks: {
          show: false, // Show or hide y-axis ticks
        },
        title: {
          show: false,
        },
      },
    },
  };
  return (
    <div className='VentesAnnuelles'>
      <div className='flex'>
        <div>Ventes annuelles</div>
      </div>

      <div className='chart-container'>
        <ReactApexChart
          options={data.options}
          series={data.series}
          type='bar'
          height={'80%'}
        />
      </div>
    </div>
  );
}

export default VentesAnnuelles;
