function isValidCardNumber(number) {
  return /^[0-9]{16}$/.test(number);
}

function isValidName(name) {
  return /^[A-Za-z\s]{2,}$/.test(name);
}

function isValidExpiryDate(date) {
  if (!/^(0[1-9]|1[0-2])\/[0-9]{2}$/.test(date)) return false;
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear() % 100;
  const currentMonth = currentDate.getMonth() + 1;
  const [expMonth, expYear] = date.split('/').map((num) => parseInt(num, 10));
  return !(
    expYear < currentYear ||
    (expYear === currentYear && expMonth <= currentMonth)
  );
}

function isValidCVV(cvv, cardNumber) {
  const isAmex = /^3[47][0-9]{13}$/.test(cardNumber);
  return isAmex ? /^[0-9]{4}$/.test(cvv) : /^[0-9]{3}$/.test(cvv);
}

export { isValidCardNumber, isValidCVV, isValidExpiryDate, isValidName };
