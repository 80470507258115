import React, { useEffect } from "react";
import "./SideBar.css";

import { Link, NavLink, useNavigate } from "react-router-dom";
import Icon from "../Icon/Icon";
import { useQuery } from "react-query";
import { logout } from "../../utils/api/auth";
import { getRoleRoutes } from "../../utils/getRoleRoutes";

function SideBar() {
  const router = useNavigate();
  const { data: user } = useQuery("profile");
  const { router: role_routes } = getRoleRoutes(user);

  useEffect(() => {
    try {
      document.querySelector(".SideBar").classList.remove("active");
      document.querySelector(".menu-button").classList.remove("active");
    } catch (error) {}
  }, [router]);
  return (
    <>
      <div className="SideBar">
        <div className="Logo">
          <img onClick={() => router("/")} src={"/images/Logo.png"} alt="" />
          <img onClick={() => router("/")} src={"/images/upload.svg"} alt="" />
        </div>
        <menu>
          <ul>
            {role_routes
              ?.filter((r) => r?.name)
              ?.map((r, i) => (
                <li key={i}>
                  <NavLink to={r?.link}>
                    <Icon name={r?.icon} />
                    <span>{r?.name}</span>
                  </NavLink>
                </li>
              ))}

            {/* <li>
              <InstallPWA />
            </li> */}
            <li>
              <NavLink to={"/login"} onClick={logout}>
                <Icon name="sign-out" />
                <span> Se déconnecter</span>
              </NavLink>
            
                <Link to={"/profile"} className="profile">
                  <img
                    src={user?.profilePicture || "/images/user.png"}
                    alt=""
                  />
                  <div>
                    <p>
                      {user?.first_name} {user?.last_name}
                    </p>
                    <span>{user?.email}</span>
                  </div>
                </Link>
             
            </li>
          </ul>
        </menu>

        <div
          className="close-menu"
          onClick={() => {
            document.querySelector(".SideBar").classList.toggle("active");
            document.querySelector(".menu-button").classList.toggle("active");
          }}
        >
          <Icon name="angle-small-left" />
        </div>
      </div>
      <div
        className="overlay"
        onClick={() => {
          document.querySelector(".SideBar").classList.toggle("active");
          document.querySelector(".menu-button").classList.toggle("active");
        }}
      ></div>
    </>
  );
}

export default SideBar;
