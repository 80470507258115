import React from 'react';
export const icons = {
  add: 'add',
  'address-book': 'address-book',
  'alarm-clock': 'alarm-clock',
  'align-center': 'align-center',
  'align-justify': 'align-justify',
  'align-left': 'align-left',
  'align-right': 'align-right',
  ambulance: 'ambulance',
  'angle-double-left': 'angle-double-left',
  'angle-double-right': 'angle-double-right',
  'angle-double-small-left': 'angle-double-small-left',
  'angle-double-small-right': 'angle-double-small-right',
  'angle-down': 'angle-down',
  'angle-left': 'angle-left',
  'angle-right': 'angle-right',
  'angle-small-down': 'angle-small-down',
  'angle-small-left': 'angle-small-left',
  'angle-small-right': 'angle-small-right',
  'angle-small-up': 'angle-small-up',
  'angle-up': 'angle-up',
  apple: 'apple',
  apps: 'apps',
  'apps-add': 'apps-add',
  'apps-delete': 'apps-delete',
  'apps-sort': 'apps-sort',
  archive: 'archive',
  'arrow-down': 'arrow-down',
  'arrow-from-bottom': 'arrow-from-bottom',
  'arrow-left': 'arrow-left',
  'arrow-right': 'arrow-right',
  'arrow-small-down': 'arrow-small-down',
  'arrow-small-left': 'arrow-small-left',
  'arrow-small-right': 'arrow-small-right',
  'arrow-small-up': 'arrow-small-up',
  'arrow-up': 'arrow-up',
  asterik: 'asterik',
  at: 'at',
  backpack: 'backpack',
  badge: 'badge',
  balloons: 'balloons',
  ban: 'ban',
  'band-aid': 'band-aid',
  bank: 'bank',
  'barber-shop': 'barber-shop',
  baseball: 'baseball',
  basketball: 'basketball',
  bed: 'bed',
  beer: 'beer',
  bell: 'bell',
  'bell-ring': 'bell-ring',
  'bell-school': 'bell-school',
  bike: 'bike',
  billiard: 'billiard',
  bold: 'bold',
  book: 'book',
  'book-alt': 'book-alt',
  bookmark: 'bookmark',
  bowling: 'bowling',
  box: 'box',
  'box-alt': 'box-alt',
  'bread-slice': 'bread-slice',
  briefcase: 'briefcase',
  broom: 'broom',
  browser: 'browser',
  brush: 'brush',
  bug: 'bug',
  building: 'building',
  bulb: 'bulb',
  butterfly: 'butterfly',
  'cake-birthday': 'cake-birthday',
  'cake-wedding': 'cake-wedding',
  calculator: 'calculator',
  calendar: 'calendar',
  'call-history': 'call-history',
  'call-incoming': 'call-incoming',
  'call-missed': 'call-missed',
  'call-outgoing': 'call-outgoing',
  camera: 'camera',
  camping: 'camping',
  car: 'car',
  'caret-down': 'caret-down',
  'caret-left': 'caret-left',
  'caret-right': 'caret-right',
  'caret-up': 'caret-up',
  carrot: 'carrot',
  'chart-connected': 'chart-connected',
  'chart-histogram': 'chart-histogram',
  'chart-network': 'chart-network',
  'chart-pie': 'chart-pie',
  'chart-pie-alt': 'chart-pie-alt',
  'chart-pyramid': 'chart-pyramid',
  'chart-set-theory': 'chart-set-theory',
  'chart-tree': 'chart-tree',
  'chat-arrow-down': 'chat-arrow-down',
  'chat-arrow-grow': 'chat-arrow-grow',
  check: 'check',
  checkbox: 'checkbox',
  cheese: 'cheese',
  'chess-piece': 'chess-piece',
  'child-head': 'child-head',
  circle: 'circle',
  'circle-small': 'circle-small',
  clip: 'clip',
  clock: 'clock',
  cloud: 'cloud',
  'cloud-check': 'cloud-check',
  'cloud-disabled': 'cloud-disabled',
  'cloud-download': 'cloud-download',
  clouds: 'clouds',
  'cloud-share': 'cloud-share',
  'cloud-upload': 'cloud-upload',
  cocktail: 'cocktail',
  coffee: 'coffee',
  comment: 'comment',
  'comment-alt': 'comment-alt',
  'comment-check': 'comment-check',
  'comment-heart': 'comment-heart',
  'comment-info': 'comment-info',
  comments: 'comments',
  'comment-user': 'comment-user',
  compress: 'compress',
  'compress-alt': 'compress-alt',
  computer: 'computer',
  confetti: 'confetti',
  cookie: 'cookie',
  copy: 'copy',
  'copy-alt': 'copy-alt',
  copyright: 'copyright',
  cow: 'cow',
  cream: 'cream',
  'credit-card': 'credit-card',
  croissant: 'croissant',
  cross: 'cross',
  'cross-circle': 'cross-circle',
  'cross-small': 'cross-small',
  crown: 'crown',
  cube: 'cube',
  cupcake: 'cupcake',
  cursor: 'cursor',
  'cursor-finger': 'cursor-finger',
  'cursor-plus': 'cursor-plus',
  'cursor-text': 'cursor-text',
  'cursor-text-alt': 'cursor-text-alt',
  dart: 'dart',
  dashboard: 'dashboard',
  database: 'database',
  'data-transfer': 'data-transfer',
  delete: 'delete',
  diamond: 'diamond',
  dice: 'dice',
  diploma: 'diploma',
  'disco-ball': 'disco-ball',
  disk: 'disk',
  doctor: 'doctor',
  document: 'document',
  'document-signed': 'document-signed',
  dollar: 'dollar',
  download: 'download',
  'drink-alt': 'drink-alt',
  drumstick: 'drumstick',
  duplicate: 'duplicate',
  earnings: 'earnings',
  edit: 'edit',
  'edit-alt': 'edit-alt',
  'e-learning': 'e-learning',
  envelope: 'envelope',
  'envelope-ban': 'envelope-ban',
  'envelope-download': 'envelope-download',
  'envelope-marker': 'envelope-marker',
  'envelope-open': 'envelope-open',
  'envelope-plus': 'envelope-plus',
  euro: 'euro',
  exclamation: 'exclamation',
  expand: 'expand',
  eye: 'eye',
  'eye-crossed': 'eye-crossed',
  'eye-dropper': 'eye-dropper',
  feather: 'feather',
  'ferris-wheel': 'ferris-wheel',
  file: 'file',
  'file-add': 'file-add',
  'file-ai': 'file-ai',
  'file-check': 'file-check',
  'file-delete': 'file-delete',
  'file-eps': 'file-eps',
  'file-gif': 'file-gif',
  'file-music': 'file-music',
  'file-psd': 'file-psd',
  fill: 'fill',
  film: 'film',
  filter: 'filter',
  fingerprint: 'fingerprint',
  fish: 'fish',
  flag: 'flag',
  flame: 'flame',
  'flip-horizontal': 'flip-horizontal',
  flower: 'flower',
  'flower-bouquet': 'flower-bouquet',
  'flower-tulip': 'flower-tulip',
  folder: 'folder',
  'folder-add': 'folder-add',
  following: 'following',
  football: 'football',
  form: 'form',
  forward: 'forward',
  fox: 'fox',
  frown: 'frown',
  ftp: 'ftp',
  gallery: 'gallery',
  gamepad: 'gamepad',
  'gas-pump': 'gas-pump',
  gem: 'gem',
  gift: 'gift',
  'glass-cheers': 'glass-cheers',
  glasses: 'glasses',
  globe: 'globe',
  'globe-alt': 'globe-alt',
  golf: 'golf',
  'graduation-cap': 'graduation-cap',
  'graphic-tablet': 'graphic-tablet',
  grid: 'grid',
  'grid-alt': 'grid-alt',
  guitar: 'guitar',
  gym: 'gym',
  hamburger: 'hamburger',
  'hand-holding-heart': 'hand-holding-heart',
  hastag: 'hastag',
  'hat-birthday': 'hat-birthday',
  headphones: 'headphones',
  headset: 'headset',
  'head-side-thinking': 'head-side-thinking',
  heart: 'heart',
  'heart-arrow': 'heart-arrow',
  home: 'home',
  'home-location': 'home-location',
  'home-location-alt': 'home-location-alt',
  hourglass: 'hourglass',
  'hourglass-end': 'hourglass-end',
  'ice-cream': 'ice-cream',
  'ice-skate': 'ice-skate',
  'id-badge': 'id-badge',
  inbox: 'inbox',
  incognito: 'incognito',
  indent: 'indent',
  infinity: 'infinity',
  info: 'info',
  interactive: 'interactive',
  interlining: 'interlining',
  interrogation: 'interrogation',
  italic: 'italic',
  jpg: 'jpg',
  key: 'key',
  keyboard: 'keyboard',
  kite: 'kite',
  label: 'label',
  laptop: 'laptop',
  lasso: 'lasso',
  laugh: 'laugh',
  layers: 'layers',
  'layout-fluid': 'layout-fluid',
  leaf: 'leaf',
  'letter-case': 'letter-case',
  'life-ring': 'life-ring',
  'line-width': 'line-width',
  link: 'link',
  lipstick: 'lipstick',
  list: 'list',
  'list-check': 'list-check',
  'location-alt': 'location-alt',
  lock: 'lock',
  'lock-alt': 'lock-alt',
  'luggage-rolling': 'luggage-rolling',
  'magic-wand': 'magic-wand',
  'makeup-brush': 'makeup-brush',
  'man-head': 'man-head',
  map: 'map',
  'map-marker': 'map-marker',
  'map-marker-cross': 'map-marker-cross',
  'map-marker-home': 'map-marker-home',
  'map-marker-minus': 'map-marker-minus',
  'map-marker-plus': 'map-marker-plus',
  marker: 'marker',
  'marker-time': 'marker-time',
  mars: 'mars',
  'mars-double': 'mars-double',
  'mask-carnival': 'mask-carnival',
  medicine: 'medicine',
  megaphone: 'megaphone',
  meh: 'meh',
  'menu-burger': 'menu-burger',
  'menu-dots': 'menu-dots',
  'menu-dots-vertical': 'menu-dots-vertical',
  microphone: 'microphone',
  'microphone-alt': 'microphone-alt',
  minus: 'minus',
  'minus-small': 'minus-small',
  mobile: 'mobile',
  'mode-landscape': 'mode-landscape',
  'mode-portrait': 'mode-portrait',
  money: 'money',
  moon: 'moon',
  mountains: 'mountains',
  mouse: 'mouse',
  'mug-alt': 'mug-alt',
  music: 'music',
  'music-alt': 'music-alt',
  navigation: 'navigation',
  network: 'network',
  'network-cloud': 'network-cloud',
  notebook: 'notebook',
  opacity: 'opacity',
  package: 'package',
  'paint-brush': 'paint-brush',
  palette: 'palette',
  'paper-plane': 'paper-plane',
  password: 'password',
  pause: 'pause',
  paw: 'paw',
  pencil: 'pencil',
  pharmacy: 'pharmacy',
  'phone-call': 'phone-call',
  'phone-cross': 'phone-cross',
  'phone-pause': 'phone-pause',
  'phone-slash': 'phone-slash',
  physics: 'physics',
  picture: 'picture',
  'ping-pong': 'ping-pong',
  'pizza-slice': 'pizza-slice',
  plane: 'plane',
  play: 'play',
  'play-alt': 'play-alt',
  'playing-cards': 'playing-cards',
  plus: 'plus',
  'plus-small': 'plus-small',
  'poker-chip': 'poker-chip',
  portrait: 'portrait',
  pound: 'pound',
  power: 'power',
  presentation: 'presentation',
  print: 'print',
  protractor: 'protractor',
  pulse: 'pulse',
  pyramid: 'pyramid',
  'quote-right': 'quote-right',
  rainbow: 'rainbow',
  raindrops: 'raindrops',
  rec: 'rec',
  receipt: 'receipt',
  'record-vinyl': 'record-vinyl',
  'rectabgle-vertical': 'rectabgle-vertical',
  'rectangle-horizontal': 'rectangle-horizontal',
  'rectangle-panoramic': 'rectangle-panoramic',
  recycle: 'recycle',
  redo: 'redo',
  'redo-alt': 'redo-alt',
  reflect: 'reflect',
  refresh: 'refresh',
  resize: 'resize',
  resources: 'resources',
  rewind: 'rewind',
  rhombus: 'rhombus',
  'rings-wedding': 'rings-wedding',
  road: 'road',
  rocket: 'rocket',
  'room-service': 'room-service',
  'rotate-right': 'rotate-right',
  rugby: 'rugby',
  sad: 'sad',
  salad: 'salad',
  scale: 'scale',
  school: 'school',
  'school-bus': 'school-bus',
  scissors: 'scissors',
  screen: 'screen',
  search: 'search',
  'search-alt': 'search-alt',
  'search-heart': 'search-heart',
  settings: 'settings',
  'settings-sliders': 'settings-sliders',
  share: 'share',
  shield: 'shield',
  'shield-check': 'shield-check',
  'shield-exclamation': 'shield-exclamation',
  'shield-interrogation': 'shield-interrogation',
  'shield-plus': 'shield-plus',
  ship: 'ship',
  'ship-side': 'ship-side',
  shop: 'shop',
  'shopping-bag': 'shopping-bag',
  'shopping-bag-add': 'shopping-bag-add',
  'shopping-cart': 'shopping-cart',
  'shopping-cart-add': 'shopping-cart-add',
  'shopping-cart-check': 'shopping-cart-check',
  shuffle: 'shuffle',
  'signal-alt': 'signal-alt',
  'signal-alt-1': 'signal-alt-1',
  'signal-alt-2': 'signal-alt-2',
  'sign-in': 'sign-in',
  'sign-in-alt': 'sign-in-alt',
  'sign-out': 'sign-out',
  'sign-out-alt': 'sign-out-alt',
  skateboard: 'skateboard',
  smartphone: 'smartphone',
  smile: 'smile',
  'smile-wink': 'smile-wink',
  snowflake: 'snowflake',
  soap: 'soap',
  soup: 'soup',
  spa: 'spa',
  speaker: 'speaker',
  sphere: 'sphere',
  spinner: 'spinner',
  'spinner-alt': 'spinner-alt',
  square: 'square',
  'square-root': 'square-root',
  star: 'star',
  'star-octogram': 'star-octogram',
  stats: 'stats',
  stethoscope: 'stethoscope',
  sticker: 'sticker',
  stop: 'stop',
  stopwatch: 'stopwatch',
  subtitles: 'subtitles',
  sun: 'sun',
  sunrise: 'sunrise',
  surfing: 'surfing',
  sword: 'sword',
  syringe: 'syringe',
  tablet: 'tablet',
  target: 'target',
  taxi: 'taxi',
  tennis: 'tennis',
  terrace: 'terrace',
  test: 'test',
  'test-tube': 'test-tube',
  text: 'text',
  'text-check': 'text-check',
  'thermometer-half': 'thermometer-half',
  'thumbs-down': 'thumbs-down',
  'thumbs-up': 'thumbs-up',
  thumbtack: 'thumbtack',
  ticket: 'ticket',
  'time-add': 'time-add',
  'time-check': 'time-check',
  'time-delete': 'time-delete',
  'time-fast': 'time-fast',
  'time-forward': 'time-forward',
  'time-forward-sixty': 'time-forward-sixty',
  'time-forward-ten': 'time-forward-ten',
  'time-half-past': 'time-half-past',
  'time-oclock': 'time-oclock',
  'time-past': 'time-past',
  'time-quarter-past': 'time-quarter-past',
  'time-quarter-to': 'time-quarter-to',
  'time-twenty-four': 'time-twenty-four',
  'tool-crop': 'tool-crop',
  'tool-marquee': 'tool-marquee',
  tooth: 'tooth',
  tornado: 'tornado',
  train: 'train',
  'train-side': 'train-side',
  transform: 'transform',
  trash: 'trash',
  treatment: 'treatment',
  tree: 'tree',
  'tree-christmas': 'tree-christmas',
  triangle: 'triangle',
  trophy: 'trophy',
  'truck-side': 'truck-side',
  umbrella: 'umbrella',
  underline: 'underline',
  undo: 'undo',
  'undo-alt': 'undo-alt',
  unlock: 'unlock',
  upload: 'upload',
  'usb-pendrive': 'usb-pendrive',
  user: 'user',
  'user-add': 'user-add',
  'user-delete': 'user-delete',
  'user-remove': 'user-remove',
  'user-time': 'user-time',
  utensils: 'utensils',
  vector: 'vector',
  'vector-alt': 'vector-alt',
  venus: 'venus',
  'venus-double': 'venus-double',
  'venus-mars': 'venus-mars',
  'video-camera': 'video-camera',
  volleyball: 'volleyball',
  volume: 'volume',
  wheelchair: 'wheelchair',
  'wifi-alt': 'wifi-alt',
  wind: 'wind',
  'woman-head': 'woman-head',
  world: 'world',
  yen: 'yen',
  'zoom-in': 'zoom-in',
  'zoom-out': 'zoom-out',
};

function Icon({
  name = icons['add'],
  style = {},
  className = '',
  click = () => {},
}) {
  return (
    <svg
      onClick={click}
      height={'1em'}
      width={'1em'}
      style={style}
      className={`${className} icon`}
    >
      <use href={`/icons/fi-rr-${name}.svg?v=9#icon`}></use>
    </svg>
  );
}

export default Icon;
